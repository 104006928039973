var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sub-wrap" },
    [
      _c("survey-paper", {
        attrs: { id: _vm.surveyPaperId, "show-modal": _vm.showSurvey },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }