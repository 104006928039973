<template>
  <div
    v-show="showModal"
    class="layer-survey-detail"
  >
    <div
      class="medical-detail-wrap"
      style="width: inherit"
    >
      <div class="layer-utility">
        <button
          class="btn-close"
          @click.prevent="beforeClose"
        >
          close
        </button>
      </div>

      <div class="md-header survey-layer-type">
        <dl>
          <dt style="font-size: 24px;">
            {{ title }}
          </dt>
          <dd>{{ startDate | $dateFormatter }}{{ startDate ? ' ~ ' : '' }}{{ endDate | $dateFormatter }}</dd>
        </dl>
      </div>

      <div class="md-contents">
        <div
          class="survey-layer-area"
        >
          <div
            v-dompurify-html="currPage ? currPage.description : ''"
            class="survey-welcome-info"
          />
          <div class="survey-list">
            <template
              v-for="q in elements"
            >
              <div
                v-if="q.isShow"
                :key="`el${q.id}`"
                :ref="`el${q.id}`"
                :class="`survey-item ${q.groupStyle ? q.groupStyle+'-question':''}`"
              >
                <dl>
                  <dt>
                    <div :class="`${q.groupStyle === 'sub' ? 'connection-questions mgt0 essential-type':''}`">
                      <label
                        v-show="q.isRequired"
                        class="essential-label"
                      >필수</label>
                      <p><span>{{ q.groupStyle === 'sub' ? '': q.dispSeq+'.' }}</span>{{ q.title }}</p>
                      <div
                        v-if="q.groupStyle === 'sub' && q.description"
                        v-dompurify-html="q.description"
                        class="s-txt"
                      />
                    </div>
                  </dt>
                  <dd v-if="q.groupStyle !== 'sub' && q.description">
                    <div
                      v-dompurify-html="q.description"
                      class="s-txt"
                    />
                  </dd>
                  <dd v-if="q.type !== 50">
                    <!-- 선택(단일) / 파이핑(단일) -->
                    <template v-if="q.type === 1 || (q.type === 9 && q.subType === 1)">
                      <ul>
                        <template
                          v-for="ch in shuffle(q.choices, q.isRandomOrder)"
                        >
                          <li :key="`sq${q.seq}-ch${ch.id}`">
                            <div class="sur-radio-wrap">
                              <input
                                :id="`sq${q.seq}-ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                :name="`el${q.id}`"
                                type="radio"
                                @change.prevent="changeAnswer(q)"
                              >
                              <label :for="`sq${q.seq}-ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`sq${q.seq}-ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer !== ch.id"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 선택(복수) / 파이핑(복수) -->
                    <template v-if="q.type === 2 || (q.type === 9 && q.subType === 2)">
                      <ul>
                        <template
                          v-for="ch in shuffle(q.choices, q.isRandomOrder)"
                        >
                          <li :key="`sq${q.seq}-ch${ch.id}`">
                            <div class="sur-checkbox-wrap">
                              <input
                                :id="`sq${q.seq}-ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                type="checkbox"
                                @change.prevent="changeAnswer(q)"
                              >
                              <label :for="`sq${q.seq}-ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`sq${q.seq}-ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 텍스트(한줄) -->
                    <template v-if="q.type === 3">
                      <div class="text-wrap">
                        <input
                          v-model="q.answer"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer(q)"
                        >
                      </div>
                    </template>

                    <!-- 텍스트(여러줄) -->
                    <template v-if="q.type === 4">
                      <div class="textarea-wrap">
                        <textarea
                          v-model="q.answer"
                          :rows="q.rows"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer(q)"
                        />
                      </div>
                    </template>

                    <!-- 순위형 / 파이핑(순위) -->
                    <template v-if="q.type === 5 || (q.type === 9 && q.subType === 3)">
                      <ul>
                        <template
                          v-for="ch in shuffle(q.choices, q.isRandomOrder)"
                        >
                          <li :key="`sq${q.seq}-ch${ch.id}`">
                            <button
                              class="btnRanking"
                              :class="{ active: ch.rank }"
                              @click.prevent="clickRank(q, ch.id)"
                            >
                              <span class="ranking"><em>{{ ch.rank }}</em></span>{{ ch.description }}
                            </button>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`sq${q.seq}-ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 비율합계형 -->
                    <template v-if="q.type === 6">
                      <template
                        v-for="ch in q.choices"
                      >
                        <div
                          :key="`ch${ch.id}`"
                          class="specific-gravity-type"
                          @click="selectInput(q.type+'_'+q.id+'_'+ch.id)"
                        >
                          <div class="sg-item">
                            <span
                              class="title"
                            >
                              <em>{{ `${ch.seq}. ${ch.description}` }}</em>
                            </span>
                            <div class="sg-write-input">
                              <input
                                :id="q.type+'_'+q.id+'_'+ch.id"
                                v-model.number="q.answer[ch.seq-1]"
                                :class="`${q.answer[ch.seq-1] > 0 ? 'haveValue':''}`"
                                @input="filteredValue(q)"
                                @change.prevent="changeAnswer(q)"
                              >
                              <span>%</span>
                            </div>
                            <div
                              class="bar-range"
                              :style="`width: ${Math.min((q.answer[ch.seq-1]/q.sumCheck)*100, 100)}%`"
                            />
                          </div>
                        </div>
                      </template>

                      <button
                        class="btn-sg-wide"
                        :disabled="q.answer.reduce((acc, val) => acc + val, 0) !== q.sumCheck"
                      >
                        현재 입력 비율 합계<span>{{ q.answer.reduce((acc, val) => acc + val, 0) }}</span>%
                      </button>
                    </template>

                    <!-- 격자형(단일) -->
                    <template v-if="q.type === 7 && q.subType === 1">
                      <div class="grid-table-type">
                        <table>
                          <colgroup>
                            <col style="width:162px">
                            <template v-for="chCol in q.choices">
                              <template
                                v-if="chCol.isColumn"
                              >
                                <col
                                  :key="`chCol${chCol.id}`"
                                  style="width:auto"
                                >
                              </template>
                            </template>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <template v-for="chCol in q.choices">
                                <template
                                  v-if="chCol.isColumn"
                                >
                                  <th :key="`chCol${chCol.id}`">
                                    {{ chCol.description }}
                                  </th>
                                </template>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="chRow in q.choices">
                              <template
                                v-if="!chRow.isColumn"
                              >
                                <tr :key="`chRow${chRow.id}`">
                                  <th>{{ chRow.description }}</th>

                                  <template v-for="chCol in q.choices">
                                    <template
                                      v-if="chCol.isColumn"
                                    >
                                      <td :key="`chRow${chRow.id}-chCol${chCol.id}`">
                                        <div class="sur-radio-noneLabel">
                                          <input
                                            :id="`radioType-${chRow.id}-${chCol.id}`"
                                            v-model="q.answer[chRow.seq-1]"
                                            type="radio"
                                            :name="`type-${chRow.id}`"
                                            :value="chCol.seq"
                                            @input="filteredValue(q)"
                                            @change.prevent="changeAnswer(q)"
                                          >
                                          <label :for="`radioType-${chRow.id}-${chCol.id}`">라벨{{ chRow.id+'-'+chCol.id }}</label>
                                        </div>
                                      </td>
                                    </template>
                                  </template>
                                </tr>
                              </template>
                            </template>

                          </tbody>
                        </table>
                      </div>
                    </template>

                    <!-- 격자형(복수) -->
                    <template v-if="q.type === 7 && q.subType === 2">
                      <div class="grid-table-type">
                        <table>
                          <colgroup>
                            <col style="width:162px">
                            <template v-for="chCol in q.choices">
                              <template
                                v-if="chCol.isColumn"
                              >
                                <col
                                  :key="`chCol${chCol.id}`"
                                  style="width:auto"
                                >
                              </template>
                            </template>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <template v-for="chCol in q.choices">
                                <template
                                  v-if="chCol.isColumn"
                                >
                                  <th :key="`chCol${chCol.id}`">
                                    {{ chCol.description }}
                                  </th>
                                </template>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="chRow in q.choices">
                              <template
                                v-if="!chRow.isColumn"
                              >
                                <tr :key="`chRow${chRow.id}`">
                                  <th>{{ chRow.description }}</th>

                                  <template v-for="chCol in q.choices">
                                    <template
                                      v-if="chCol.isColumn"
                                    >
                                      <td :key="`chRow${chRow.id}-chCol${chCol.id}`">
                                        <div class="survey-radio-noneLabel">
                                          <input
                                            :id="`checkboxType-${chRow.id}-${chCol.id}`"
                                            v-model="q.answer[chRow.seq-1][chCol.seq-1]"
                                            type="checkbox"
                                            :name="`type-${chRow.id}-${chCol.id}`"
                                            @input="filteredValue(q)"
                                            @change.prevent="changeAnswer(q)"
                                          >
                                          <label :for="`checkboxType-${chRow.id}-${chCol.id}`">라벨{{ chCol.id }}</label>
                                        </div>
                                      </td>
                                    </template>
                                  </template>
                                </tr>
                              </template>
                            </template>

                          </tbody>
                        </table>
                      </div>
                    </template>

                    <!-- 척도형 -->
                    <template v-if="q.type === 8">
                      <div class="scale-type">
                        <div class="scale-items">
                          <template v-for="point in (Array.from({ length: q.scaleLevel }, (v, i) => i + q.minValue))">
                            <div
                              :key="q.type+'_'+q.id+'_'+point"
                              class="scale-radio-type"
                            >
                              <input
                                :id="`scaleType${q.type+'_'+q.id+'_'+point}`"
                                v-model="q.answer"
                                type="radio"
                                :name="`scaleType${q.type+'_'+q.id}`"
                                :value="point"
                                @change.prevent="changeAnswer(q)"
                              >
                              <label :for="`scaleType${q.type+'_'+q.id+'_'+point}`">{{ point }}</label>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="guide-txt">
                        <span class="txt-left">{{ q.minDesc }}</span>
                        <span>{{ q.medDesc }}</span>
                        <span class="txt-right">{{ q.maxDesc }}</span>
                      </div>
                    </template>

                    <!-- 자동제출(100+) -->
                    <template v-if="q.type === 100">
                      &nbsp;
                    </template>
                  </dd>
                </dl>
              </div>
              <div
                v-show="getErrorMessage(q.id)"
                :key="`invalid${q.id}`"
                class="message-box"
              >
                <p class="essential-message">
                  {{ getErrorMessage(q.id) }}
                </p>
              </div>
            </template>
          </div>

          <div
            v-if="elements && elements.length"
            class="btn-wrap"
          >
            <button
              :class="currPageIdx > 0 ? 'btn-sendIn' : 'btn-before'"
              @click.prevent="prevPage"
            >
              이전
            </button>
            <button
              v-if="pages.length && currPageIdx < pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              다음
            </button>
            <button
              v-if="pages.length && currPageIdx === pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              제출하기
            </button>
          </div>
        </div>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { setError, setException } from '@/common/error/log'
import { setStatistics } from '@/common/statistics/service'
import { changeUserPoint } from '@/auth/utils'
import { errorFormatter, numberFormatter } from '@/libs/utils/filters'

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },

    entry: {
      type: Number,
      required: false,
      default: null,
    },

    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      title: null,
      startDate: null,
      endDate: null,
      pages: [],
      elements: [],
      oldAnswers: [],
      currPage: null,
      currPageIdx: null,
      pagePaths: [],
      errorMessages: {},

      // 문항 1개라도 입력값이 변경된 경우 true
      modified: false,
      // 페이지 연결 문항이 1개라도 변경된 경우 true
      modifiedPagePath: false,
    }
  },

  computed: {
    ...mapGetters({
      medicalDept: 'infoData/getMedicalDept',
      shaYoyangNum: 'infoData/getShaYoyangNum',
      // shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
    pageNav() {
      return [...new Set(this.pagePaths.map(x => x % 100))]
    },
    linkPages() {
      return [...new Set(this.pagePaths.filter(x => x !== ((x % 100) * 101)))]
    }
  },

  watch: {
    id(to) {
      if (to) {
        this.fetchSurveyPaper(to)
      }
    },
  },

  methods: {
    prevPage() {
      if (!this.currPageIdx) {
        return
      }

      // 이전 페이지로 이동 시, 현재 페이지 변경정보 저장X => 페이지 변경점이 있었다면 다시 false로 되돌림
      this.modifiedPagePath = false

      const prevLinkArray = this.pageNav.filter(num => num - 1 < this.currPageIdx)
      // 페이지 경로에서 이전 페이지 탐색
      const prevPage = Math.max(...prevLinkArray) - 1
      this.loadPage(prevPage)
    },

    async nextPage() {
      // 페이지 이동(또는 제출 전) Validation
      const inValidElements = this.validate()
      if (inValidElements.length) {
        const firstErrorRef = `el${inValidElements[0]}`
        this.$refs[firstErrorRef][0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
        return
      }
      // Validation end.

      // @@ 현재 페이지의 연결 페이지 추가 start
      // 현재 페이지 연결정보가 있다면 제거
      // this.pagePaths = this.pagePaths.filter(x => x < (this.currPageIdx + 1) * 100 || (this.currPageIdx + 2) * 100 <= x)
      // 현재 페이지에서 종료(90202) 옵션 처리 추가
      this.pagePaths = this.pagePaths.filter(x => Math.floor((x % 10000) / 100) < (this.currPageIdx + 1) || (this.currPageIdx + 1) < Math.floor((x % 10000) / 100))
      // 연결 정보 스캔 : 현재 페이지 정보(currPageInfo)는 항상 추가
      const currPageInfo = (this.currPageIdx + 1)
      let currLinks = []
      for (let i = 0; i < this.elements.length; i += 1) {
        if (this.elements[i].type === 1) {
          // 선택[단일]형
          const choice = this.elements[i].choices.filter(ch => ch.id === this.elements[i].answer)
          for (let c = 0; c < choice.length; c += 1) {
            currLinks = [...new Set([...currLinks, ...choice[c].linkPageList])]
          }
        } else if (this.elements[i].type === 2) {
          // 선택[복수]형
          const choices = this.elements[i].choices.filter(ch => this.elements[i].answer.includes(ch.id))
          for (let c = 0; c < choices.length; c += 1) {
            currLinks = [...new Set([...currLinks, ...choices[c].linkPageList])]
          }
        }
        // 이외 유형은 연결 페이지 없음
      }
      // 현재 페이지에서 종료되는 연결이 있다면 구분 (+ 90000)
      // 현재페이지 정보 추가 (+ i*100)
      currLinks = currLinks.map(x => (x === currPageInfo ? x + 90000 : x)).map(x => x + currPageInfo * 100)
      this.pagePaths = [...new Set([...this.pagePaths, ...currLinks, currPageInfo * 101])]
      // // 연결 페이지 집합 갱신 >> computed 사용
      // this.pageNav = [...new Set(this.pagePaths.map(x => x % 100))]
      // @@ 현재 페이지의 연결 페이지 추가 end

      // 대상 페이지 중 현재 페이지와 가장 가까운 다음 페이지 로드
      let isLastPage = (this.currPageIdx + 1) === this.pages.length
      const nextLinkArray = this.pageNav.filter(num => num - 1 > this.currPageIdx)
      let nextPage = null
      if (nextLinkArray.length > 0) {
        nextPage = Math.min(...nextLinkArray) - 1
      } else if (this.linkPages.length > 0) {
        // 연결이 시작된 후라면, 링크 페이지가 없는 경우 설문 종료
        isLastPage = true
      } else {
        // 연결이 없었다면, 다음 페이지로 이동
        nextPage = this.currPageIdx + 1
      }

      if (this.currPageIdx !== null && (isLastPage)) {
        // 마지막 페이지인 경우
        this.showAlertDialog('미리보기 모드에서는 제출이 불가합니다.')
      } else {
        let loadPageIdx = null
        if (this.currPage === null) {
          loadPageIdx = 0
        } else {
          loadPageIdx = nextPage
        }

        this.loadPage(loadPageIdx)
      }
    },

    loadPage(pageIdx) {
      // 페이지 로드
      this.currPageIdx = pageIdx
      this.currPage = this.pages[pageIdx]
      this.fetchPage(this.currPage.id)
    },

    getErrorMessage(elementId) {
      return Object.keys(this.errorMessages).length === 0 ? null : (this.errorMessages[elementId] || null)
    },

    // Validation
    validate() {
      const inValidElements = []
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        let isValid = true
        const el = elements[i]
        if (el.isShow === false) {
          // 숨겨진 문항은 Validation 체크 제외
          this.$delete(this.errorMessages, el.id)
          continue
        }
        // 필수 입력 체크
        if (el.isRequired) {
          if (el.type === 100) {
            // 자동제출 유형
            if (!el.autoAnswers || el.autoAnswers.length === 0) {
              isValid = false
            } else {
              let existResults = 0
              for (let j = 0; j < el.autoAnswers.length; j += 1) {
                if (el.autoAnswers[j].autoResult) {
                  existResults += 1
                }
              }
              isValid = (existResults > 0)
            }
          } else if (el.type === 6) {
            // 비율합계
            if (el.answer.reduce((acc, val) => acc + val, 0) === 0) {
              isValid = false
            }
          } else if (el.type === 7 && el.subType === 1) {
            // 격자형 : 라디오 (체크박스는 임의로 false 응답 가능)
            if (el.answer.filter(x => x === false).length > 0) {
              isValid = false
            }
          } else if (el.type === 8) {
            // 척도형 : 0부터 시작 가능
            if (el.answer === null) {
              isValid = false
            }
          } else if (!el.answer || el.answer.length === 0) {
            // 그 외 유형
            isValid = false
          }

          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)
            // if (el.type === 6) {
            //   this.$set(this.errorMessages, el.id, `총 비율이 ${el.sumCheck}%여야 합니다.`)
            // } else
            if (el.type === 7 && el.subType === 1) {
              this.$set(this.errorMessages, el.id, '답변 필수 문항입니다. 응답하지 않은 문항이 있습니다.')
            } else {
              this.$set(this.errorMessages, el.id, '답변 필수 문항입니다.')
            }
          }
        }
        // 기타텍스트 입력 여부(선택[단일,복수], 순위형, 파이핑형에 해당)
        if (isValid && (el.type === 1 || el.type === 2 || el.type === 5 || el.type === 9)) {
          let etcId = 0
          for (let j = 0; j < el.choices.length; j += 1) {
            if (el.choices[j].isEtc) {
              etcId = el.choices[j].id
            }
          }
          if (etcId !== 0) {
            if (el.type === 1 && el.answer === etcId && !el.answerEtcText) {
              isValid = false
            } else if ((el.type === 2 || el.type === 5) && el.answer.indexOf(etcId) !== -1 && !el.answerEtcText) {
              isValid = false
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, '기타 내용을 입력하여 주시기 바랍니다.')
            }
          }
        }
        // 텍스트 답변 형식 체크
        // 전체(null or 0 or ''), 문자만(1), 숫자만(2)
        if (isValid && (el.type === 3 || el.type === 4)) {
          if (!!el.textType && (el.answer !== null && el.answer !== '')) {
            if (el.textType === 1) {
              if (!new RegExp('^([^0-9]*)$').test(el.answer)) {
                isValid = false
              }
            } else if (el.textType === 2) {
              if (!new RegExp('^[0-9]*$').test(el.answer)) {
                isValid = false
              }
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, `${el.textType === 1 ? '문자' : '숫자'}만 입력 가능합니다.`)
            }
          }
        }
        // 최소 체크 개수 충족여부 (2,3,4,5)
        if (isValid && el.minCheck !== null && el.answer !== null) {
          if (el.type === 2) {
            // 선택(복수)형
            if (el.answer.length === 0) {
              isValid = true
            } else if (el.minCheck > el.answer.length) {
              isValid = false
            }
          } else if (el.type === 3 && el.textType === 2) {
            // 텍스트(한줄) 숫자형
            if (el.answer === null || el.answer === '') {
              // isValid 가 true인 경우 필수 답변이면 답이 있음 (입력 전엔 null, 필터링 후엔 '')
              // 답이 없는 경우는 선택 답변밖에 없다. => 답이 없는 경우는 체크하지 않음
              isValid = true
            } else if (el.minCheck > el.answer) {
              isValid = false
            }
          } else if ((el.type === 3 && el.textType !== 2) || el.type === 4) {
            // 텍스트 유형 (여러줄, 문자)
            if (el.answer === null || el.answer === '') {
              isValid = true
            } else if (el.minCheck > el.answer.length) {
              isValid = false
            }
          } else if (el.type === 5) {
            // 순위형
            if (el.answer.length === 0) {
              isValid = true
            } else if (el.minCheck > el.answer.length) {
              isValid = false
            }
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 && el.textType === 2) {
              // 텍스트 유형 한줄, 숫자
              inValidMessage = `최소 ${el.minCheck} 이상 입력하여 주시기 바랍니다.`
            } else if ((el.type === 3 && el.textType !== 2) || el.type === 4) {
              // 텍스트 유형 (여러줄, 문자)
              inValidMessage = `최소 ${el.minCheck}글자 이상 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최소 ${el.minCheck}개 이상 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
        // 최대 체크 개수 충족여부 (2,3,4,5)
        if (isValid && el.maxCheck !== null && el.answer !== null) {
          if (el.type === 2) {
            // 선택(복수)형
            if (el.answer.length === 0) {
              isValid = true
            } else if (el.maxCheck < el.answer.length) {
              isValid = false
            }
          } else if (el.type === 3 && el.textType === 2) {
            // 텍스트(한줄) 숫자형
            if (el.answer === null || el.answer === '') {
              // isValid 가 true인 경우 필수 답변이면 답이 있음 (입력 전엔 null, 필터링 후엔 '')
              // 답이 없는 경우는 선택 답변밖에 없다. => 답이 없는 경우는 체크하지 않음
              isValid = true
            } else if (el.maxCheck < el.answer) {
              isValid = false
            }
          } else if ((el.type === 3 && el.textType !== 2) || el.type === 4) {
            // 텍스트 유형 (여러줄, 문자)
            if (el.answer === null || el.answer === '') {
              isValid = true
            } else if (el.maxCheck < el.answer.length) {
              isValid = false
            }
          } else if (el.type === 5) {
            // 순위형
            if (el.answer.length === 0) {
              isValid = true
            } else if (el.maxCheck < el.answer.length) {
              isValid = false
            }
          } else if (el.maxCheck < el.answer.length) {
            // 체크
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 && el.textType === 2) {
            // 숫자 유형
              inValidMessage = `최대 ${el.maxCheck} 미만 입력하여 주시기 바랍니다.`
            } else if ((el.type === 3 && el.textType !== 2) || el.type === 4) {
              // 텍스트 유형
              inValidMessage = `최대 ${el.maxCheck}글자 미만 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최대 ${el.maxCheck}개 이하로 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
        // 비율합계 총 비율 충족여부
        if (isValid && el.type === 6) {
          const sumValue = el.answer.reduce((acc, val) => acc + val, 0)
          if (sumValue !== 0 && sumValue !== el.sumCheck) {
            // 선택인 경우 0 값으로 통과 가능
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 6) {
              // 100% 충족 필수
              inValidMessage = `총 비율이 ${el.sumCheck}%여야 합니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
        // // 격자형 : 라디오
        // if (isValid && el.type === 7 && el.subType === 1 && el.isRequired) {
        //   if (el.answer.filter(x => x === false).length > 0) {
        //     isValid = false
        //   }
        //   if (isValid) {
        //     this.$delete(this.errorMessages, el.id)
        //   } else {
        //     inValidElements.push(el.id)

        //     let inValidMessage = ''
        //     if (el.type === 7 && el.subType === 1 && el.isRequired) {
        //       // 필수 선택
        //       inValidMessage = `응답하지 않은 문항이 있습니다.`
        //     }
        //     this.$set(this.errorMessages, el.id, inValidMessage)
        //   }
        // }
      } // for i end.
      return inValidElements
    },

    arraysAreEqual(ids1, ids2) {
      if (ids1.length !== ids2.length) return false
      return ids1.every((id) => ids2.includes(id))
    },

    // 현재 페이지에 표시될 문항의 연결 여부를 확인하여 재 표시
    showElements() {
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        const el = elements[i]

        /// @@ 파이핑 문항인 경우 연결문항의 답변 정보 갱신
        if (el.type === 9) {
          if (el.refElementId === null) {
            // 파이핑 설정이 잘못되어 참조문항 정보가 없는 경우
            // choice가 없는 상태로 파이핑 표시 + 기존 답변 제거
            el.choices = []
            if (el.subType === 1) { // 단일
              el.answer = null
            } else { // 복수,순위
              el.answer = []
            }
          } else {
            const refElement = elements.find(x => x.id === el.refElementId)

            const oldChoiceIds = el.choices.map(choice => choice.id)
            const newChoiceIds = refElement.answer

            const isChange = !this.arraysAreEqual(oldChoiceIds, newChoiceIds)

            // 선택지 변동이 없는 경우 갱신하지 않음
            if (isChange) {
            // 선택지가 변경된 경우 기존 답변을 제거하고 선택지 갱신

              if (refElement.answer.length === 0) {
                // 선택지가 없는 경우 초기화
                el.choices = []
              } else if (refElement.answer.length > 0) {
              // 기존 답변 제거
                if (el.subType === 1) { // 단일
                  el.answer = null
                } else { // 복수,순위
                  el.answer = []
                }

                const choices = this.shuffle(refElement.choices, refElement.isRandomOrder).filter(x => refElement.answer.indexOf(x.id) !== -1).map(x => ({ ...x }))

                this.$set(el, 'choices', choices)
              }
            }
          }
        }

        // 표시여부 판별
        const isShow = this.isShowElement(el.seq)

        this.$set(el, 'isShow', isShow) // 문항 표시 설정
        if (isShow === false) {
          // 숨겨진 문항은 답변 초기화
          if (el.type === 2 || el.type === 5 || (el.type === 9 && el.subType === 2) || (el.type === 9 && el.subType === 3)) {
            el.answer = []
          } else if (el.type === 6) {
            el.answer = Array.from({ length: el.choices.length }, () => 0)
          } else if (el.type === 7) {
            el.answer = this.initGridAnswerArray(el)
          } else el.answer = null

          if (el.answerEtcText) {
            el.answerEtcText = null
          }

          // 숨겨진 문항은 Validation 체크 제외
          this.$delete(this.errorMessages, el.id)
        }
      } // for i end

      // 문항번호 재 지정
      this.resetElementSequence()
    },

    isPageChange(changeElement) {
      // 현재 문항에 연결 페이지가 있는지 검사
      const cLinkPages = changeElement.choices.filter(x => x.linkPages !== "")
      if (cLinkPages.length > 0) {
        return true
      }

      // 현재 문항에 연결 문항이 있는지 검사
      const cShowElements = changeElement.choices.filter(x => x.showElements !== "")
      if (cShowElements.length > 0) {
        // 연결 문항이 있는 보기 순회
        for (let choiceIdx = 0; choiceIdx < cShowElements.length; choiceIdx += 1) {
          const elementSeqs = cShowElements[choiceIdx].showElementList
          // 보기에 연결된 문항 순회
          for (let seqIdx = 0; seqIdx < elementSeqs.length; seqIdx += 1) {
            // 연결 페이지가 있는지 검사 ... (재귀)
            const cElement = this.elements.find(x => x.seq === elementSeqs[seqIdx])
            if (this.isPageChange(cElement)) {
              return true
            }
          }
        }
        return true
      }

      return false
    },

    // 해당 문항 표시 여부 반환
    isShowElement(elementSeq) {
      const { elements } = this

      let isShow = null
      for (let i = 0; i < elements.length; i += 1) {
        if (isShow === true) break // 다른 문항에서 이미 판별중인 문항의 값이 true가 된 경우 더이상 검사 X

        const el = elements[i]

        if (el.type === 1 || el.type === 2) {
          // 선택형 문항[ 선택(단일), 선택(복수) ]에 대한 체크
          const { choices } = el

          // 보기문항 목록 순회
          for (let j = 0; j < choices.length; j += 1) {
            if (isShow === true) break // 다른 보기에서 이미 판별중인 문항의 값이 true가 된 경우 더이상 검사 X

            const ch = choices[j]

            // showElement에 체크 문항이 포함되어 있는 경우 답변 여부 체크
            if (ch.showElementList && ch.showElementList.length && ch.showElementList.indexOf(elementSeq) !== -1) {
              if (el.answer && el.answer === ch.id) {
                // 순회중인 문항에서 단일선택된 답변이 isShow 판별중인 문항과 연결된 보기인 경우 => 판별 문항 노출
                isShow = true
              } else if (el.answer && typeof el.answer === 'object' && el.answer.indexOf(ch.id) !== -1) {
                // 순회중인 문항에서 복수선택된 답변이 isShow 판별중인 문항과 연결된 보기인 경우 => 판별 문항 노출
                isShow = true
              } else {
                isShow = false
              }
            }
          } // for j end
        }
      } // for i end

      return isShow === null ? true : isShow
    },

    // 문항 표시 변경 이후 번호 재 지정
    resetElementSequence() {
      const { elements } = this

      let elementIdx = 1
      let groupIdx = 0

      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].isShow !== false) {
          if (elements[i].groupStyle === 'sub') {
            elements[i].dispSeq = `${elementIdx - 1}-${groupIdx}`
            groupIdx += 1
          } else {
            if (elements[i].groupStyle === 'top') {
              groupIdx = 1 // 그룹핑 top 문항이 나온 경우 그룹 인덱스 초기화
            }
            elements[i].dispSeq = elementIdx // 문항표시번호 기본 값
            elementIdx += 1
          }
        }
      }
    },

    clickRank(el, choiceId) {
      const { answer } = el

      if (choiceId) {
        const existIdx = answer.indexOf(choiceId)
        if (existIdx !== -1) {
          // 선택목록에 들어 있으면
          answer.splice(existIdx, 1)
        } else {
          answer.push(choiceId)
        }
      }

      this.changeAnswer(el)
      this.refreshRank(el)
    },

    refreshRank(el) {
      const { answer } = el
      const { choices } = el

      // 선택목록 순서대로 Rank 재 할당
      for (let i = 0; i < choices.length; i += 1) {
        this.$set(choices[i], 'rank', null)
      }
      for (let i = 0; i < answer.length; i += 1) {
        for (let j = 0; j < choices.length; j += 1) {
          if (answer[i] === choices[j].id) {
            this.$set(choices[j], 'rank', (i + 1))
          }
        }
      }
    },

    changeAnswer(el) {
      if (el.type === 1 || el.type === 2) {
        // 이미 저장된 답변이 있고, 처음 변경인 경우 검사
        if (this.oldAnswers !== null && this.oldAnswers.filter(x => x.surveyElementId === el.id).length > 0 && !this.modifiedPagePath && this.isPageChange(el)) {
          this.showConfirmDialog('해당 문항의 답변을 변경할 경우,\n다음 페이지에서 기존에 답변하신 내용이 모두 초기화됩니다.\n변경하시겠습니까?', (result) => {
            if (!result) {
              // 취소 시 롤백
              let ele = el
              ele = this.oldAnswerSet(el, this.element)
              this.showElements()
              return
            }
            this.modifiedPagePath = true
            // pagePaths 갱신 (다음 페이지 이후 내역들 제거)
            this.pagePaths = this.pagePaths.filter(x => Math.floor((x % 10000) / 100) < (this.currPageIdx + 2))
          })
        }
      }
      this.setChangeAnswer()
    },

    setChangeAnswer() {
      this.modified = true

      this.showElements()
    },

    filteredValue(element) {
      const el = element
      if (el.answer === null || el.answer === '') {
        return
      }

      let inputValue = el.answer

      if (el.maxCheck && inputValue.length > el.maxCheck) {
        inputValue = inputValue.slice(0, el.maxCheck)
      }
      if (el.textType === 1) {
        inputValue = inputValue.replace(/[0-9]/, '')
      }
      if (el.textType === 2) {
        inputValue = inputValue.replace(/[^0-9]/g, '')
      }
      if (el.type === 6) {
        for (let i = 0; i < inputValue.length; i += 1) {
          inputValue[i] = Number(inputValue[i] ? inputValue[i].toString().replace(/[^0-9]/g, '') : 0)
        }
      }

      el.answer = inputValue
    },

    fetchSurveyPaper(id) {
      axios.get(`/fu/survey-preview/${id}`)
      // axios.get(`/fu/survey/paper/${id}`, { params: { userHash: this.shaEnLicenseNum } })
        .then(rs => {
          this.title = rs.data.title
          this.pages = rs.data.pages
          this.pagePaths = rs.data.pagePath ? rs.data.pagePath?.split(",").map(Number) : []

          if (this.pages.length === 0) {
            this.showAlertDialog('설문 정보가 잘못 입력되었습니다.\n관리자에게 문의해 주세요.')
            return
          }

          // const { continuePageIdx, continueStatus } = rs.data
          // if (continueStatus !== null && continueStatus === 5) { // 5: 제출완료
          //   this.loadRewardPage()
          // } else if (continuePageIdx !== null) {
          //   this.loadPage(continuePageIdx)
          // } else {
          this.loadPage(0)
          // }
        })
        .catch(err => {
          this.showAlertDialog(errorFormatter(err, '설문조사를 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.'))
        })
    },

    fetchPage(pageId) {
      axios.get(`/fu/survey-preview/elements/${pageId}`)
      // axios.get(`/fu/survey/elements/${this.id}/${pageId}`, { params: { userHash: this.shaEnLicenseNum } })
        .then(rs => {
          const { elements } = rs.data
          this.oldAnswers = null

          let elementIdx = 1
          let groupIdx = 0

          for (let i = 0; i < elements.length; i += 1) {
            const el = elements[i]

            // el = this.oldAnswerSet(el, elements)

            // 답변 초기화
            if (!el.answer) {
              if (el.type === 2 || el.type === 5 || (el.type === 9 && el.subType === 2) || (el.type === 9 && el.subType === 3)) {
              // 선택[복수]형(2), 순위형(5), 파이핑[복수]형(9-2), 파이핑[순위]형(9-3) 설문은 배열 값으로 초기화
                el.answer = []
              } else if (el.type === 6) {
                // 비율합계형(6)
                el.answer = Array.from({ length: el.choices.length }, () => 0)
              } else if (el.type === 7) {
                // 격자[단일]형(7-1), 격자[복수]형(7-2)
                el.answer = this.initGridAnswerArray(el)
              } else {
                // 선택[단일]형(1), 텍스트한줄[textType:문자,숫자]형(3-1,3-2), 텍스트여러줄형(4), 척도형(8), 파이핑[단일]형(9-1)
                el.answer = null
              }
            }

            el.isShow = true // 보여주기 기본 값 true
            if (el.groupStyle === 'sub') {
              el.dispSeq = `${elementIdx - 1}-${groupIdx}`
              groupIdx += 1
            } else {
              if (el.groupStyle === 'top') {
                groupIdx = 1 // 그룹핑 top 문항이 나온 경우 그룹 인덱스 초기화
              }
              el.dispSeq = elementIdx // 문항표시번호 기본 값
              elementIdx += 1
            }
          }

          this.elements = elements
          this.showElements()

          // 2페이지부터 스크롤 위치는 상단으로 변경
          if (this.currPageIdx && elements.length) {
            this.$nextTick(() => {
              const firstElement = `el${elements[0].id}`
              this.$refs[firstElement][0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
            })
          }
        })
        .catch(() => {
          this.showAlertDialog('페이지 문항을 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },

    beforeClose() {
      this.close(false)
    },

    close() {
      this.title = null
      this.startDate = null
      this.endDate = null
      this.pages = []
      this.elements = []
      this.currPage = null
      this.currPageIdx = null
      this.errorMessages = {}
      this.modified = false
      this.modifiedPagePath = false
    },

    userhashToRandom() {
      const seed = 'preview'
      // const seed = this.shaEnLicenseNum
      let hash = 0
      for (let i = 0; i < seed.length; i += 1) {
        hash = (hash * 32) - hash + seed.charCodeAt(i) // 간단한 해시 함수
      }
      return Math.abs(hash)
    },

    shuffle(array, isRandom) {
      if (isRandom) {
        const random = this.userhashToRandom()
        const shuffledArray = [...array]

        for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
          const j = (random + i) % (i + 1); // 무작위 인덱스 생성
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]] // Swap
        }
        return shuffledArray
      }
      return array
    },

    initGridAnswerArray(el) {
      let rowCount = 0
      let colCount = 0
      // 격자형(7)은 행렬의 크기로 초기화
      for (let r = 0; r < el.choices.length; r += 1) {
        if (el.choices[r].isColumn) {
          if (el.choices[r].seq > colCount) { colCount = el.choices[r].seq }
        } else if (el.choices[r].seq > rowCount) { rowCount = el.choices[r].seq }
      }

      if (el.subType === 1) {
        // 단일인 경우 행 크기로 초기화
        return Array.from({ length: rowCount }, () => false)
      }
      // 복수인 경우 행렬 크기로 초기화
      return Array.from({ length: rowCount }, () => Array(colCount).fill(false))
    },

    selectInput(id) {
      const inputElement = document.getElementById(id)
      inputElement.focus()
      inputElement.select()
    },

  },
}
</script>
