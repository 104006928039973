<template>
  <section
    class="sub-wrap"
  >
    <survey-paper
      :id="surveyPaperId"
      :show-modal="showSurvey"
    />
  </section>
</template>

<script>
import SurveyPaper from './components/SurveyPaper.vue'

export default {
  components: {
    SurveyPaper,
  },

  data() {
    return {
      surveyPaperId: null,
      showSurvey: false,
    }
  },
  created() {
    window.addEventListener("message", this.receiveMessage, false)
  },
  mounted() {
  },
  methods: {
    receiveMessage(e) {
      if (e.origin === process.env.VUE_APP_ADMIN_URL) {
        this.surveyPaperId = e.data

        if (this.surveyPaperId) this.showSurvey = true
      }
    },
  },
}
</script>

<style>
.drPickWrap {
  min-width: auto !important;
  overflow-y: auto !important;
}

.sub-wrap {
  height: auto !important;
}
</style>
